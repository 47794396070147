import * as React from 'react';
import { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { LoadingButton } from '@mui/lab';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Done, ChevronRight, ChevronLeft } from '@mui/icons-material';
import quote from '../../assets/quote.pdf';
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import { Fab } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { apiUrl } from '../../utils';

interface SignProps {
    handleNext: Function,
    handleBack: Function
}


function ESign(props: SignProps) {

    const [envelopeId, setEnvelopeId] = useState('');
    const [docuSignUrl, setdocuSignUrl] = useState('');
    const [isLoadingContract, setIsLoadingContract] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [isCheckingEnvelopeStatus, setIsCheckingEnvelopeStatus] = useState(false);

    React.useEffect(() => {

        setIsLoadingContract(true);
        const params = new URLSearchParams(window.location.search)
        let rid = params.get('rid');

        if(envelopeId){
            return;
        }
        console.log("********** calling docusign***********");

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("rid", rid);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(apiUrl + "/embeddedLink", requestOptions)
            .then(response => {
                if (response.ok) {
                    console.log(response);
                    return response.text();
                }
                throw new Error('Something went wrong');
            })
            .then(result => {
                const jsonResult = JSON.parse(result);
                console.log(jsonResult);
                //window.location.replace(jsonResult['url']);
                setdocuSignUrl(jsonResult['url']);
                setEnvelopeId(jsonResult['envelopeId']);
                setIsLoadingContract(false);
            })
            .catch(error => {
                console.log('error', error);
                setAlertMessage('Something went wrong try refreshing the page');
                showErrorAlert(true);
            });


    }, []);

    const handleAlertClose = () => {
        setShowErrorAlert(false);
    };



    const handleContinue = () => {

        if (envelopeId != '') {
            setIsCheckingEnvelopeStatus(true);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("envelopeId", envelopeId);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };

            fetch(apiUrl + "/envelopeDetails", requestOptions)
                .then(response => {
                    if (response.ok) {
                        return response.text();
                    }
                    throw new Error('Something went wrong');
                })
                .then(result => {
                    const jsonResult = JSON.parse(result);
                    setIsCheckingEnvelopeStatus(false);
                    if (jsonResult['status'] == "completed") {
                        setShowErrorAlert(false);
                        props.handleNext();
                    }
                    else {
                        setAlertMessage('Sign the document before continuing to payment');
                        setShowErrorAlert(true);
                        setTimeout(handleAlertClose, 4000);

                    }
                })
                .catch(error => {
                    console.log('error', error);
                    setIsCheckingEnvelopeStatus(false);
                    setAlertMessage('Something went wrong try refreshing the page');
                    setShowErrorAlert(true);
                    setTimeout(handleAlertClose, 4000);
                });
        } else {
            setAlertMessage('Something went wrong try refreshing the page');
            showErrorAlert(true);
        }

    };

    return (
        <React.Fragment>
            <CssBaseline />

            <Box sx={{
                paddingTop: '0px',
                paddingLeft: '60px',
                paddingRight: '60px',
                paddingBottom: '20px',
                bgcolor: '#EFEFEF',
                height: '100vh'
            }}>
                {isLoadingContract ?

                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        mt={5}
                    >
                        <CircularProgress />
                    </Stack>

                    :

                    <Grid container spacing={4}
                            style={{
                                height: '80%'
                            }}>


                        <Grid item xs={10}
                            style={{
                                overflow: 'scroll',
                                height: '100%'
                            }}>
                            {docuSignUrl != '' &&
                                <iframe src={docuSignUrl} style={{ width: '100%', height: '100%' }} />
                            }

                        </Grid>

                        <Grid item xs={2}>

                            <Stack spacing={2}>
                                {/* 
                            <Typography variant="h6" style={{ color: 'black' }}>
                                Invoice #2145-5121
                            </Typography>

                            <Grid container spacing={0}>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1">
                                        Type
                                    </Typography>
                                </Grid>

                                <Grid item xs={8}>
                                    <Typography variant="subtitle1" style={{ color: 'black' }}>
                                        Amount
                                    </Typography>

                                </Grid>

                            </Grid>

                            <Grid container spacing={0}>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1">
                                        Display
                                    </Typography>
                                </Grid>

                                <Grid item xs={8}>
                                    <Typography variant="subtitle1" style={{ color: 'black' }}>
                                        $88254.00
                                    </Typography>

                                </Grid>

                            </Grid>

                            <Grid container spacing={0}>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1">
                                        Subscription
                                    </Typography>
                                </Grid>

                                <Grid item xs={8}>
                                    <Typography variant="subtitle1" style={{ color: 'black' }}>
                                        $2117.64/month
                                    </Typography>

                                </Grid>

                            </Grid>

                            <Grid container spacing={0}>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1">
                                        Shipping
                                    </Typography>
                                </Grid>

                                <Grid item xs={8}>
                                    <Typography variant="subtitle1" style={{ color: 'black' }}>
                                        $1102.83
                                    </Typography>

                                </Grid>

                            </Grid>

                            <Grid container spacing={0}>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1">
                                        Total
                                    </Typography>
                                </Grid>

                                <Grid item xs={8}>
                                    <Typography fontWeight="800" variant="subtitle1" style={{ color: 'black' }}>
                                        $89356.83 USD
                                    </Typography>

                                </Grid>

                            </Grid>
                            */}

                                {!isLoadingContract &&

                                    <LoadingButton
                                        onClick={handleContinue}
                                        endIcon={<ChevronRight />}
                                        loading={isCheckingEnvelopeStatus}
                                        loadingPosition="end"
                                        variant="contained"
                                    >
                                        <span>Continue to payment</span>
                                    </LoadingButton>

                                }

                                {showErrorAlert &&
                                    <Alert variant="outlined" severity="error" onClose={handleAlertClose}>
                                        {alertMessage}
                                    </Alert>
                                }

                            </Stack>

                        </Grid>

                        <Fab color="primary" aria-label="back"
                        style={{
                            position: "fixed", left: "0", bottom: "0",
                            marginLeft: "40px", marginBottom: "10px"
                        }}
                        onClick={props.handleBack}
                        size="medium">
                        <ChevronLeft />
                    </Fab>
                    
                    </Grid>

                }



            </Box>

        </React.Fragment>
    );
}

export default ESign;