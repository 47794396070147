import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { Stack } from '@mui/material';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import ShippingDetails from './fragments/ShippingDetails';
import ESign from './fragments/ESign';
import Payment from './fragments/Payment';
import PaymentComplete from './fragments/PaymentComplete';
import NoPage from './NoPage';
import { CircularProgress } from '@mui/material';
import { Button } from '@mui/material';
import { apiUrl } from '../utils';

const steps = ['Fill up the form', 'E-sign', 'Payment'];


function MasterStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [dataFetched, setDataFetched] = React.useState(false);
  const [isPaymentCompleted, setIsPaymentCompleted] = React.useState(false);
  const [needNewContract, setNeedNewContract] = React.useState(false);
  const [customerDetails, setCustomerDetails] = React.useState({});
  const [companyDetails, setCompanyDetails] = React.useState({});
  const [fetchError, setFetchError] = React.useState("");
  const [pdfFileLink, setPDFFileLink] = React.useState("");
  

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    let rid = params.get('rid')
    let state = params.get('state')

    sessionStorage.setItem("rid" , rid);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("rid", rid);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    fetch(apiUrl + "/customerDetails", requestOptions)
      .then(response => {
        if (response.ok) {
          return response.text();
        }
        throw new Error('Something went wrong');
      })
      .then(result => {
        setDataFetched(true);
        const jsonData = JSON.parse(result);

        if(jsonData['payment_status'] == "completed")
        {
          setIsPaymentCompleted(true);
          return;
        }

        if(jsonData['contract_status'] == "pending")
        {
          setNeedNewContract(true);
          return;
        }
        setCustomerDetails(jsonData['customerDetails']);
        setCompanyDetails(jsonData['companyDetails']);
        //console.log('result is '+jsonData['customerDetails'])
      })
      .catch(error => {
        setDataFetched(false);
        setFetchError('error fetching data ' + error);
        //console.log('error is '+error)
      });

    const prevActiveStep = sessionStorage.getItem("activeStep");

    if (prevActiveStep != null) {
      setActiveStep(parseInt(prevActiveStep))
    }

  }, []);

  React.useEffect(() => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    const params = new URLSearchParams(window.location.search);
    let dealID = params.get('rid');
    var urlencoded = new URLSearchParams();
    urlencoded.append("rid", dealID);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow',
    };

    fetch(apiUrl + "/signedDocument", requestOptions)
      .then(response => {
        if (response.ok) {
          
            return response.text();
        }
          
         else {
        throw new Error('Something went wrong');
        }
      })
      .then(result => {
        const jsonData = JSON.parse(result);
        setPDFFileLink(`data:application/pdf;base64,${jsonData['result']}`);

      })
      .catch(error => {
        console.log('error is '+error)
      });

  }, [isPaymentCompleted])

  const isStepOptional = (step) => {
    return false;
  };


  const handleNext = () => {
    sessionStorage.setItem("activeStep", activeStep + 1);
    setActiveStep((activeStep) => activeStep + 1);
  };

  const handleBack = () => {
    sessionStorage.setItem("activeStep", activeStep - 1);
    setActiveStep((activeStep) => activeStep - 1);
  };


  const handleReset = () => {
    sessionStorage.setItem("activeStep", 0);
    setActiveStep(0);
  };



  return (
    <Box sx={{ padding: '0px' }}>
      {dataFetched ?

      isPaymentCompleted ?

      <Box sx={{ padding: '20px', margin: '0px' }}>

          <Stack alignItems="center">
            <Typography variant='h6'>
              You have successfully signed the contract and completed the payment.
            </Typography>

          { pdfFileLink &&
            <a href={pdfFileLink} download="contract.pdf">
            <Button>Download Contract</Button>
            </a>
            }

            </Stack>
          </Box>

      :

      needNewContract ? 

      <Box sx={{ padding: '10px', margin: '0px' }}>

            <Typography>
              We will send you a new link with updated contract as you have updated your personal info. If you have any question contact support team.
            </Typography>

          </Box>
        :
        <Box sx={{ padding: '0px' }}>
          <Stepper
            style={{
              paddingLeft: '60px',
              paddingRight: '60px',
              paddingTop: '20px',
              paddingBottom: '20px'
            }}
            activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === 0 ? (
            <React.Fragment>
              <ShippingDetails handleNext={handleNext} customerDetails={customerDetails} companyDetails={companyDetails} />
            </React.Fragment>
          ) :
            activeStep === 1 ?
              (
                <React.Fragment>
                  <ESign handleNext={handleNext} handleBack={handleBack} />
                </React.Fragment>
              ) :
              activeStep === 2 ?
                (
                  <React.Fragment>
                    <Payment handleNext={handleNext} handleBack={handleBack} />
                  </React.Fragment>
                ) :
                activeStep === 3 ?
                (
                  <React.Fragment>            
                    <PaymentComplete />
                  </React.Fragment>
                ) :
                (
                  <React.Fragment>
                    <NoPage />
                  </React.Fragment>
                )
          }
        </Box>
        :
        fetchError == '' ?

          <Box sx={{ padding: '0px', margin: '0px' }}>

            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              mt={5}
            >
              <CircularProgress />
            </Stack>

          </Box>
          :
          <Box sx={{ padding: '0px', margin: '0px' }}>

            <Typography>
              fetchError
            </Typography>

          </Box>

      }

    </Box>
  );
}

export default MasterStepper;