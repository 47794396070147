import { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material';
import Layout from "./pages/Layout";
import NoPage from "./pages/NoPage";
import MasterStepper from "./pages/MasterStepper";

const theme = createTheme({
  typography: {
    fontFamily: [
      'Proxima Nova',
      'sans-serif',
    ].join(',')
 },
 palette: {
  background: {
    default: "#FFFFFF"
  }
}
});



function App() {

  const [showMobileWarning, setShowMobileWarning] = useState(false)

  useEffect(() => {
    if(window.innerWidth <= 800)
      setShowMobileWarning(true)
  }, [])

  if(showMobileWarning) {

    return (
      <div>
        <h5>
          Please open the website on desktop
        </h5>
      </div>
    )
  }

  return (
    

    <ThemeProvider theme={theme}>
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<MasterStepper />} />
        
        <Route path="*" element={<NoPage />} />
      </Route>
    </Routes>
  </BrowserRouter>
  </ThemeProvider>
  );
}

export default App;
