import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { apiUrl } from '../../utils';


function PaymentComplete(props) {

    React.useEffect(() => {

        var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var urlencoded = new URLSearchParams();

            const params = new URLSearchParams(window.location.search);
            let dealID = params.get('rid');
            urlencoded.append("status", 'payment completed');
            urlencoded.append("rid", dealID);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };

            fetch(apiUrl + '/paymentstatus', requestOptions)
                .then((res) => {
                    if (res.ok) {
                        return res.text()
                    }
                    else {
                        throw new Error('Something went wrong');
                    }
                })
                .then((data) => {
                    const jsonData = JSON.parse(data);
                    //console.log(jsonData)
                    window.location.reload();
                    return
                })
                .catch((err) => {
                    
                    console.log(err.message);

                });
    }, []);

    return (
        <React.Fragment>
            <CssBaseline />
            <Box sx={{
                paddingTop: '20px',
                paddingLeft: '20%',
                paddingRight: '20%',
                paddingBottom: '20px',
                bgcolor: '#EFEFEF',
                height: '100vh'
            }}>
                { /*
                    <Fab color="primary" aria-label="back"
                        style={{
                            position: "fixed", left: "0", bottom: "0",
                            marginLeft: "40px", marginBottom: "10px"
                        }}
                        onClick={props.handleBack}
                        size="medium">
                        <ChevronLeft />
                    </Fab>
                    */
                }


                <Box>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        mt={10}>
                        <Typography>
                            Payment completed successfully
                        </Typography>
                    </Stack>
                </Box>


            </Box>

        </React.Fragment>
    );
}

export default PaymentComplete;