import React, { useEffect, useState } from "react";
import { LoadingButton } from '@mui/lab';
import { hostUrl } from "../utils";
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';

import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { Stack } from "@mui/material";


export default function StripeCheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(props.isPaymentProcessing);
  const [isPaymentFailed, setIsPaymentFailed] = useState(false);
  const [refreshPaymentState, setRefreshPaymentState] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    if (!isPaymentProcessing) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(function (result) {
      console.log(result)
      if (result.paymentIntent) {
        switch (result.paymentIntent.status) {
          case "succeeded":
            props.paymentCallback(true)
            setMessage("Payment succeeded!");
            break;
          case "processing":
            setTimeout(() => {
              setRefreshPaymentState(!refreshPaymentState);
            }, 10000);
            setMessage("Your payment is processing, this may take a while.");
            break;
          case "requires_payment_method":
            props.onPaymentProcessingChange(false)
            setIsPaymentProcessing(false)
            setIsPaymentFailed(true);
            setMessage("Your payment was not successful, please try again.");
            break;
          default:
            alert("Your payment was not successful, please try again.");
            props.onPaymentProcessingChange(false)
            setIsPaymentProcessing(false)
            setIsPaymentFailed(true);
            setMessage("Payment not processed, try again");
            break;
        }
      }
      else {
        setTimeout(() => {
          setRefreshPaymentState(!refreshPaymentState);
        }, 1000);
      }
    });

  }, [stripe, refreshPaymentState]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    let rid = sessionStorage.getItem("rid");
    const email = sessionStorage.getItem("email");

    if (rid == undefined) {
      const params = new URLSearchParams(window.location.search)
      rid = params.get('rid')
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: hostUrl + "?rid=" + rid,
        receipt_email: email,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setIsLoading(false);
      setMessage(error.message);
    } else {
      setIsLoading(false);
      setMessage("An unexpected error occurred.");
    }


  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <div>
      {!isPaymentProcessing ?
        <form id="payment-form" onSubmit={handleSubmit}>

          <PaymentElement id="payment-element" options={paymentElementOptions} />

          <Stack
            direction="row"
            justifyContent="center"
            mt={5}>
            <LoadingButton
              id="submit"
              loading={isLoading}
              variant="contained"
              type="submit"
            >
              <span id="button-text">
                Pay now
              </span>
            </LoadingButton>
          </Stack>

        </form>
        :
        <Box>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            {!isPaymentFailed &&
            <CircularProgress />
            }

            {/* Show any error or success messages */}
            {message &&
              <div id="payment-message">{message}</div>}
          </Stack>
        </Box>
      }
    </div>
  );
}