import * as React from 'react';
import { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Done } from '@mui/icons-material';
import { Fab, colors } from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { ShippingInfo } from '../../interfaces/shippingInfo.interface';
import { loadStripe } from "@stripe/stripe-js";
import { width } from '@mui/system';
import StripeCheckoutForm from '../../stripeForm/stripeCheckoutForm';
import { Elements } from "@stripe/react-stripe-js";
import { apiUrl } from '../../utils';
import { CircularProgress } from '@mui/material';


interface PaymentProps {
    handleNext: Function,
    handleBack: Function,
}

const PaymentCard = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

const stripePromise = loadStripe('pk_test_HBkNWwVQuefqGE8GVjCWBJZp');

const appearance = {
    theme: 'stripe',

    variables: {
        colorPrimary: '#0570de',
        colorBackground: '#ffffff',
        colorText: '#30313d',
        colorDanger: '#df1b41',
        fontFamily: 'Proxima Nova,sans-serif'
        // See all possible variables below
    }
};


function Payment(props: PaymentProps) {


    const [clientSecret, setClientSecret] = useState('');
    const [subscriptionAmount, setSubscriptionAmount] = useState('');
    const [shippingInfo, setShippingInfo] = useState({});
    const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);
    const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
    const [loadingError, setLoadingError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    
    const [paymentMessage, setPaymentMessage] = useState('');


    useEffect(() => {

        console.log("isPaymentProcessing"+isPaymentProcessing);

        if (isPaymentCompleted) {
            return
        }

        const params = new URLSearchParams(window.location.search)
        let rid = params.get('rid')

        let payment_intent_client_secret = params.get('payment_intent_client_secret');

        console.log(payment_intent_client_secret);

        if (payment_intent_client_secret != null) {
            setClientSecret(payment_intent_client_secret);
            setIsPaymentProcessing(true);
            setSubscriptionAmount(sessionStorage.getItem("amount"));
            return
        }

        console.log("client secret is "+clientSecret)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        urlencoded.append("rid", rid);

        console.log("********** calling payment intent***********");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(apiUrl + '/paymentLink', requestOptions)
            .then((res) => {
                if (res.ok) {
                    return res.text()
                }
                else {
                    throw new Error('Something went wrong');
                }
            })
            .then((data) => {
                const jsonData = JSON.parse(data);
                setClientSecret(jsonData['client_secret']);
                setSubscriptionAmount(jsonData['amount']);
                sessionStorage.setItem("amount", jsonData['amount']);
                
            })
            .catch((err) => {
                console.log(err.message);
                setLoadingError(true);
                setErrorMessage(err.message);
            });

    }, []);

    

    const paymentCallback = (status) => {
        props.handleNext()
      };

      const onPaymentProcessingChange = (status) => {
        setIsPaymentProcessing(status)
      }

    const options = {
        clientSecret,
        appearance,
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <Box sx={{
                paddingTop: '20px',
                paddingLeft: '20%',
                paddingRight: '20%',
                paddingBottom: '20px',
                bgcolor: '#EFEFEF',
                height: '100vh'
            }}>
                { !isPaymentProcessing &&
                    <Fab color="primary" aria-label="back"
                        style={{
                            position: "fixed", left: "0", bottom: "0",
                            marginLeft: "40px", marginBottom: "10px"
                        }}
                        onClick={props.handleBack}
                        size="medium">
                        <ChevronLeft />
                    </Fab>
                    }
                    

                {clientSecret != '' ?
                    <Box
                        style={{
                            overflow: 'scroll',
                            height: '80vh'
                        }}>
                        {!isPaymentCompleted &&

                            <PaymentCard>
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    mt={1}
                                    mb={2}>
                                        { !isPaymentProcessing &&
                                    <Typography variant="h6" style={{color: 'black'}} >
                                        Pay {subscriptionAmount} USD First and Last month subscription amount.
                                    </Typography>
                                    }
                                </Stack>
                                <Elements options={options} stripe={stripePromise}>
                                    <StripeCheckoutForm paymentCallback={paymentCallback} isPaymentProcessing={isPaymentProcessing} onPaymentProcessingChange={onPaymentProcessingChange} />
                                </Elements>
                            </PaymentCard>
                        }
                        
                    </Box>
                    :
                    loadingError ?
                        <Box>
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography>
                                    {errorMessage}
                                </Typography>
                            </Stack>
                        </Box>
                        :
                        <Box>
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <CircularProgress />
                            </Stack>
                        </Box>

                    }
            </Box>
        </React.Fragment>
    );
}

export default Payment;