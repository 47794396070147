import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { orange } from '@mui/material/colors';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Bolt, ChevronRight } from '@mui/icons-material';
import ShippingInformationDialog from '../dialogForms/ShippingInformation';
import { borderRadius } from '@mui/system';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import * as utils from '../../utils';
import { ShippingInfo } from '../../interfaces/shippingInfo.interface';
import { EndUserDetails } from '../../interfaces/endUserDetails.interface';
import { apiUrl } from '../../utils';

interface ShippingDetailsProps {
    handleNext: Function,
    customerDetails: any,
    companyDetails: any
}

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


const ShippingFormItem = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    textAlign: 'left',
    height: '70vh',
    color: theme.palette.text.secondary,
}));

function ShippingDetails(props: ShippingDetailsProps) {

    const contactID = props.customerDetails.id;
    const customerDetails = props.customerDetails.properties;
    const companyID = props.companyDetails.id;
    const companyDetails = props.companyDetails.properties;

    const [displaySize, setDisplaySize] = React.useState('');
    const [selectedCountry, setCountry] = React.useState('United States');
    const [selectedState, setSelectedState] = React.useState('AL');
    const [statesLabel, setStatesLabel] = React.useState('State');
    const [states, setStates] = React.useState(utils.usStates);
    //const [shippingInfo, setShippingInfo] = React.useState({ contactname: customerDetails.firstname + " "+customerDetails.lastname, email: customerDetails.email, street: "647 Pleasant Street", city: "Lewiston", zip: "42400", phone: customerDetails.phone, state: "ME", country: "US" })
    const [endUserDetails, setEndUserDetails] = React.useState({});
    const [voltage, setVoltage] = React.useState(208);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [isUpdatingDetails, setIsUpdatingDetails] = React.useState(false);
    const [showErrorAlert, setShowErrorAlert] = React.useState(false);

    React.useEffect(() => {

        /* var retrievedObject = localStorage.getItem('shippingInfo');

        if (retrievedObject == null) {
            return
        }
        var savedInfo: ShippingInfo
        savedInfo = JSON.parse(retrievedObject)
        setShippingInfo(savedInfo)
        */
    }, [dialogOpen]);

    React.useEffect(() => {

        /*var retrievedObject = localStorage.getItem('endUserDetails');

        if (retrievedObject == null) {
            return
        }
        var savedInfo: EndUserDetails
        savedInfo = JSON.parse(retrievedObject)
        setEndUserDetails(savedInfo)

        reset(savedInfo)
        console.log(savedInfo)
        */

        if (companyDetails) {
            if (companyDetails.country == "United States" || companyDetails.country == "US") {
                setStates(utils.usStates);
                setStatesLabel('State');
                setSelectedState(companyDetails.state);
                setCountry('United States');
            }
            else if (companyDetails.country == "Canada" || companyDetails.country == "CA") {
                setStates(utils.canadianStates);
                setStatesLabel('Province');
                setSelectedState(companyDetails.state);
                setCountry('Canada');
            }

        }
    }, []);

    const displaySizeChange = (event) => {
        setDisplaySize(event.target.value);
    };

    const handleCountryChange = (event) => {
        setCountry(event.target.value);

        if (event.target.value == "United States") {
            setStates(utils.usStates);
            setStatesLabel("State")
            setSelectedState("AL")
        }
        else {
            setStates(utils.canadianStates);
            setStatesLabel("Province")
            setSelectedState("AB")
        }
    }

    const handleStateChange = (event) => {
        setSelectedState(event.target.value);
    }


    const voltageChange = (event) => {
        setVoltage(event.target.value);
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleAlertClose = () => {
        setShowErrorAlert(false);
    };

    const validationSchema = Yup.object().shape({
        firstname: Yup.string()
            .required('First name is required'),
        lastname: Yup.string(),
        email: Yup.string()
            .required('Email is required')
            .email('Email is invalid'),
        street: Yup.string()
            .required('Street is required'),
        city: Yup.string()
            .required('City is required'),
        zip: Yup.string()
            .required('Zip code is required')
            .max(6, 'Invalid zip code')
            .min(5, 'Invalid zip code')

        //correctShippingAddress: Yup.bool().oneOf([true], 'Confirm shipping address is required')
    });

    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        defaultValues: {
            firstname: customerDetails.firstname,
            lastname: customerDetails.lastname,
            email: customerDetails.email,
            street: companyDetails ? companyDetails.address : "",
            city: companyDetails ? companyDetails.city : "",
            zip: companyDetails ? companyDetails.zip : "",
        },
        resolver: yupResolver(validationSchema)
    }, []);

    const dialogValidationSchema = Yup.object().shape({
        contactname: Yup.string()
            .required('Contact name is required')
            .default('some value'),

        email: Yup.string()
            .required('Email is required')
            .email('Email is invalid'),
        street: Yup.string()
            .required('Street is required'),

        city: Yup.string()
            .required('City is required'),

        zip: Yup.string()
            .required('Zip code is required')
            .max(6, 'Invalid zip code')
            .min(5, 'Invalid zip code'),


    });

    const onSubmit = data => {
        //console.log(data);
        //console.log(selectedState);
        //console.log(selectedCountry);
        //localStorage.setItem('endUserDetails', JSON.stringify(data, null, 2));
        //sessionStorage.setItem("email", data['email'])
        //props.handleNext()
        if (data.firstname == customerDetails.firstname
            && data.lastname == customerDetails.lastname
            && data.email == customerDetails.email
            && data.street == companyDetails.address
            && data.city == companyDetails.city
            && data.zip == companyDetails.zip
            && selectedState == companyDetails.state
            && selectedCountry == companyDetails.country) {
            console.log("nothing changed")
            sessionStorage.setItem("email", data['email']);
            props.handleNext()
            return
        }

        if (data.firstname != customerDetails.firstname
            || data.lastname != customerDetails.lastname
            || data.email != customerDetails.email) {

            setIsUpdatingDetails(true);
            console.log("name or email changed");
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var urlencoded = new URLSearchParams();

            const params = new URLSearchParams(window.location.search);
            let dealID = params.get('rid');
            urlencoded.append("dealID", dealID);
            urlencoded.append("changes", "personal");
            urlencoded.append("contactID", contactID);
            urlencoded.append("firstname", data.firstname);
            urlencoded.append("lastname", data.lastname);
            urlencoded.append("email", data.email);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };

            fetch(apiUrl + '/updateDetails', requestOptions)
                .then((res) => {
                    if (res.ok) {
                        return res.text()
                    }
                    else {
                        throw new Error('Something went wrong');
                    }
                })
                .then((data) => {
                    setIsUpdatingDetails(false);
                    const jsonData = JSON.parse(data);
                    //console.log(jsonData)
                    window.location.reload();
                    return
                })
                .catch((err) => {
                    setIsUpdatingDetails(false);
                    setShowErrorAlert(true);
                    console.log(err.message);

                });
        }
        if (data.street != companyDetails.address
            || data.city != companyDetails.city
            || data.zip != companyDetails.zip) {
            console.log("Address changed");
            setIsUpdatingDetails(true);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var urlencoded = new URLSearchParams();
            urlencoded.append("changes", "address");
            urlencoded.append("companyID", companyID);
            urlencoded.append("street", data.street);
            urlencoded.append("city", data.city);
            urlencoded.append("zip", data.zip);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };

            fetch(apiUrl + '/updateDetails', requestOptions)
                .then((res) => {
                    if (res.ok) {
                        return res.text()
                    }
                    else {
                        throw new Error('Something went wrong');
                    }
                })
                .then((data) => {
                    setIsUpdatingDetails(false);
                    const jsonData = JSON.parse(data);
                    //console.log(jsonData)

                })
                .catch((err) => {
                    setIsUpdatingDetails(false);
                    setShowErrorAlert(true);
                    console.log(err.message);

                });
            
        }

        if (selectedState != companyDetails.state
            || selectedCountry != companyDetails.country) {
            console.log("Country changed");
            setIsUpdatingDetails(true);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var urlencoded = new URLSearchParams();
            urlencoded.append("changes", "country");
            urlencoded.append("companyID", companyID);
            urlencoded.append("state", selectedState);
            urlencoded.append("country", selectedCountry);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };

            fetch(apiUrl + '/updateDetails', requestOptions)
                .then((res) => {
                    if (res.ok) {
                        return res.text()
                    }
                    else {
                        throw new Error('Something went wrong');
                    }
                })
                .then((data) => {
                    setIsUpdatingDetails(false);
                    const jsonData = JSON.parse(data);
                    //console.log(jsonData)

                })
                .catch((err) => {
                    setIsUpdatingDetails(false);
                    setShowErrorAlert(true);
                    console.log(err.message);

                });
        }

        sessionStorage.setItem("email", data['email']);
        props.handleNext();
    };

    return (

        <React.Fragment>
            <CssBaseline />

            <Box sx={{
                paddingTop: '0px',
                paddingLeft: '60px',
                paddingRight: '60px',
                paddingBottom: '20px',
                bgcolor: '#EFEFEF',
                height: '100vh'
            }}>


                <Grid container spacing={2}
                    style={{
                        overflow: 'scroll',
                        height: '100%'
                    }}>
                    <Grid item xs={10}>

                        <Stack spacing={2}>

                            <ShippingFormItem>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography variant="h6" style={{ color: 'black' }}>
                                        Your Details
                                    </Typography>

                                    <Typography variant="subtitle2"
                                        style={{
                                            backgroundColor: "#228B22",
                                            color: "white",
                                            paddingLeft: "10px",
                                            paddingRight: "10px",
                                            borderRadius: "5px"
                                        }}>
                                        This is required before E-signing
                                    </Typography>

                                </Stack>

                                <Typography variant="subtitle1">
                                    ScreenHub Account creation (key contact who will be using ScreenHub at install location)
                                </Typography>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1 },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <TextField
                                                required
                                                id="firstname"
                                                name="firstname"
                                                label="First name"
                                                variant="standard"
                                                style={{ width: '90%' }}
                                                {...register('firstname')}
                                                error={errors.firstname ? true : false}
                                                inputProps={{ style: { fontSize: 16 } }}
                                                InputLabelProps={{ style: { fontSize: 16 } }}
                                            />

                                            <Typography variant="inherit" color="textSecondary">
                                                {errors.firstname?.message}
                                            </Typography>

                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                required
                                                id="lastname"
                                                name="lastname"
                                                label="Last name"
                                                variant="standard"
                                                style={{ width: '90%' }}
                                                {...register('lastname')}
                                                error={errors.lastname ? true : false}
                                                inputProps={{ style: { fontSize: 16 } }}
                                                InputLabelProps={{ style: { fontSize: 16 } }}
                                            />

                                            <Typography variant="inherit" color="textSecondary">
                                                {errors.lastname?.message}
                                            </Typography>

                                        </Grid>

                                    </Grid>

                                    <Grid container spacing={1}>

                                        <Grid item xs={6}>
                                            <TextField
                                                required
                                                id="email"
                                                name="email"
                                                label="Email"
                                                variant="standard"
                                                style={{ width: '90%' }}
                                                {...register('email')}
                                                error={errors.email ? true : false}
                                                inputProps={{ style: { fontSize: 16 } }}
                                                InputLabelProps={{ style: { fontSize: 16 } }}
                                            />

                                            <Typography variant="inherit" color="textSecondary">
                                                {errors.email?.message}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                required
                                                id="street"
                                                name="street"
                                                label="Street"
                                                variant="standard"
                                                style={{ width: '90%' }}
                                                {...register('street')}
                                                error={errors.street ? true : false}
                                                inputProps={{ style: { fontSize: 16 } }}
                                                InputLabelProps={{ style: { fontSize: 16 } }}
                                            />

                                            <Typography variant="inherit" color="textSecondary">
                                                {errors.street?.message}
                                            </Typography>

                                        </Grid>

                                    </Grid>

                                    <Grid container spacing={1}>

                                        <Grid item xs={6}>
                                            <TextField
                                                required
                                                id="city"
                                                name="city"
                                                label="City"
                                                variant="standard"
                                                style={{ width: '90%' }}
                                                {...register('city')}
                                                error={errors.city ? true : false}
                                                inputProps={{ style: { fontSize: 16 } }}
                                                InputLabelProps={{ style: { fontSize: 16 } }}
                                            />
                                            <Typography variant="inherit" color="textSecondary">
                                                {errors.city?.message}
                                            </Typography>

                                        </Grid>

                                        <Grid item xs={6}>
                                            <FormControl variant="standard" sx={{ m: 1, width: "90%" }}>
                                                <InputLabel id="states-label">{statesLabel}</InputLabel>
                                                <Select
                                                    labelId="states"
                                                    id="states"
                                                    value={selectedState}
                                                    onChange={handleStateChange}
                                                    label={statesLabel}
                                                >
                                                    {states.map((state) => (
                                                        <MenuItem
                                                            key={state.name}
                                                            value={state.abbreviation}
                                                        >
                                                            {state.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>

                                            </FormControl>
                                        </Grid>

                                    </Grid>

                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <TextField
                                                required
                                                id="zip"
                                                name="zip"
                                                label="Zip"
                                                {...register('zip')}
                                                error={errors.zip ? true : false}
                                                variant="standard"
                                                style={{ width: '90%' }}
                                                inputProps={{ style: { fontSize: 16 } }}
                                                InputLabelProps={{ style: { fontSize: 16 } }}
                                            />

                                            <Typography variant="inherit" color="textSecondary">
                                                {errors.zip?.message}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={6}>

                                            <FormControl variant="standard" sx={{ m: 1, width: "90%" }}>
                                                <InputLabel id="demo-simple-select-standard-label">Country</InputLabel>
                                                <Select
                                                    labelId="demo-multiple-name-label"
                                                    id="demo-multiple-name"
                                                    value={selectedCountry}
                                                    onChange={handleCountryChange}
                                                    label="Country"
                                                >
                                                    {utils.countries.map((country) => (
                                                        <MenuItem
                                                            key={country}
                                                            value={country}
                                                        >
                                                            {country}
                                                        </MenuItem>
                                                    ))}
                                                </Select>

                                            </FormControl>

                                        </Grid>


                                    </Grid>

                                </Box>
                            </ShippingFormItem>




                        </Stack>
                    </Grid>

                    {/*
                    <Grid item xs={6}>

                        <Stack spacing={3}>

                            <ShippingFormItem>

                                <Stack direction="column" alignItems="left" sx={{ justifyContent: 'space-between' }}>
                                    <Stack direction="row" justifyContent="space-between">
                                        <Typography variant="h6" style={{ color: 'black' }}>
                                            Install Site Information
                                        </Typography>
                                        <Button variant="outlined" onClick={handleDialogOpen}>Edit Information</Button>
                                        
                                    </Stack>

                                    <Stack alignItems="left">
                                    <Typography variant="subtitle1">
                                            If below shipping information is incorrect, you can edit it before E-signing
                                        </Typography>

                                    </Stack>

                                </Stack>

                                <Box sx={{ mt: '10px' }}>

                                    <Grid container spacing={0}>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1">
                                                Shipping Street
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <Typography variant="subtitle1" style={{ color: 'black' }}>
                                                {shippingInfo.street}
                                            </Typography>

                                        </Grid>

                                    </Grid>

                                    <Grid container spacing={0}
                                        sx={{ background: '#F8F8F8' }}>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1">
                                                Shipping City
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <Typography variant="subtitle1" style={{ color: 'black' }}>
                                                {shippingInfo.city}
                                            </Typography>

                                        </Grid>

                                    </Grid>

                                    <Grid container spacing={0}>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1">
                                                Shipping State
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <Typography variant="subtitle1" style={{ color: 'black' }}>
                                                {shippingInfo.state}
                                            </Typography>

                                        </Grid>

                                    </Grid>

                                    <Grid container spacing={0}
                                        sx={{ background: '#F8F8F8' }}>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1">
                                                Shipping Country
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <Typography variant="subtitle1" style={{ color: 'black' }}>
                                                {shippingInfo.country}
                                            </Typography>

                                        </Grid>

                                    </Grid>

                                    <Grid container spacing={0}>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1">
                                                Shipping Zip
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <Typography variant="subtitle1" style={{ color: 'black' }}>
                                                {shippingInfo.zip}
                                            </Typography>

                                        </Grid>

                                    </Grid>

                                    <Grid container spacing={0}
                                        sx={{ background: '#F8F8F8' }}>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1">
                                                Contact Name
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <Typography variant="subtitle1" style={{ color: 'black' }}>
                                                {shippingInfo.contactname}
                                            </Typography>

                                        </Grid>

                                    </Grid>

                                    <Grid container spacing={0}>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1">
                                                Contact Email
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <Typography variant="subtitle1" style={{ color: 'black' }}>
                                                {shippingInfo.email}
                                            </Typography>

                                        </Grid>

                                    </Grid>

                                    <Grid container spacing={0}
                                        sx={{ background: '#F8F8F8' }}>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1">
                                                Contact Phone
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <Typography variant="subtitle1" style={{ color: 'black' }}>
                                                {shippingInfo.phone}
                                            </Typography>

                                        </Grid>

                                    </Grid>

                                    <FormControlLabel
                                        control={
                                            <Controller
                                                control={control}
                                                name="correctShippingAddress"
                                                defaultValue="false"
                                                inputRef={register()}
                                                render={({ field: { onChange } }) => (
                                                    <Checkbox
                                                        sx={{
                                                            color: orange[800],
                                                            '&.Mui-checked': {
                                                                color: orange[600],
                                                            },
                                                        }}
                                                        onChange={e => onChange(e.target.checked)}
                                                    />
                                                )}
                                            />
                                        }
                                        label={
                                            <Typography color={errors.correctShippingAddress ? 'error' : 'inherit'}>
                                                Confirm this address as the correct shipping location?
                                            </Typography>
                                        }
                                    />
                                    <br />
                                    <Typography variant="inherit" color="textSecondary">
                                        {errors.correctShippingAddress
                                            ? '(' + errors.correctShippingAddress.message + ')'
                                            : ''}
                                    </Typography>



                                </Box>
                            </ShippingFormItem>
                        </Stack>
                        { /*
                        <Stack spacing={1}>

                            <Typography variant="h6" style={{ color: 'black' }}>
                                Electrical Requirement
                            </Typography>

                            <Typography variant="subtitle1">
                                Required power setup based on display size and voltage *
                            </Typography>

                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue={voltage}
                                    name="radio-buttons-group"
                                    onChange={voltageChange}>

                                    <Box sx={{
                                        width: '100%',
                                        background: '#F8F8F8',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        columnGap: '2rem',
                                        borderRadius: '4px'
                                    }}>
                                        <Box sx={{
                                            padding: '10px'
                                        }}>

                                            <Stack direction="row" alignItems="center" gap={1}>
                                                <Bolt style={{ color: voltage == 120 ? 'orange' : 'black' }} />
                                                <Typography variant="h6" style={{ color: voltage == 120 ? 'orange' : 'black' }}>
                                                    120V
                                                </Typography>
                                            </Stack>

                                            <Typography variant="subtitle1" style={{ color: 'black' }}>
                                                4 lines of power at 20 amps
                                            </Typography>

                                            <Typography variant="subtitle2">
                                                1 line of power into controller and 1 into boosters
                                            </Typography>

                                        </Box>

                                        <Box sx={{

                                        }}>

                                            <FormControlLabel
                                                value="120"
                                                control={<Radio
                                                    sx={{
                                                        color: orange[800],
                                                        '&.Mui-checked': {
                                                            color: orange[600],
                                                        },
                                                    }}
                                                />}
                                            />

                                        </Box>

                                    </Box>

                                    <Box sx={{
                                        width: '100%',
                                        background: '#F8F8F8',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        columnGap: '2rem',
                                        borderRadius: '4px',
                                        mt: '10px'
                                    }}>
                                        <Box sx={{
                                            padding: '10px'
                                        }}>

                                            <Stack direction="row" alignItems="center" gap={1}>
                                                <Bolt style={{ color: voltage == 208 ? 'orange' : 'black' }} />
                                                <Typography variant="h6" style={{ color: voltage == 208 ? 'orange' : 'black' }}>
                                                    208V
                                                </Typography>
                                            </Stack>

                                            <Typography variant="subtitle1" style={{ color: 'black' }}>
                                                2 lines of power at 20 amps
                                            </Typography>

                                            <Typography variant="subtitle2">
                                                1 line of power into controller and 1 into boosters
                                            </Typography>

                                        </Box>

                                        <Box sx={{

                                        }}>

                                            <FormControlLabel
                                                value="208"
                                                control={<Radio
                                                    sx={{
                                                        color: orange[800],
                                                        '&.Mui-checked': {
                                                            color: orange[600],
                                                        },
                                                    }}
                                                />}
                                            />

                                        </Box>

                                    </Box>

                                    <Box sx={{
                                        width: '100%',
                                        background: '#F8F8F8',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        columnGap: '2rem',
                                        borderRadius: '4px',
                                        mt: '10px'
                                    }}>
                                        <Box sx={{
                                            padding: '10px'
                                        }}>

                                            <Stack direction="row" alignItems="center" gap={1}>
                                                <Bolt style={{ color: voltage == 240 ? 'orange' : 'black' }} />
                                                <Typography variant="h6" style={{ color: voltage == 240 ? 'orange' : 'black' }}>
                                                    240V
                                                </Typography>
                                            </Stack>

                                            <Typography variant="subtitle1" style={{ color: 'black' }}>
                                                1 line of power at 20 amps
                                            </Typography>

                                            <Typography variant="subtitle2">
                                                1 line of power into controller and 0 into booster
                                            </Typography>

                                        </Box>

                                        <Box sx={{

                                        }}>

                                            <FormControlLabel
                                                value="240"
                                                control={<Radio
                                                    sx={{
                                                        color: orange[800],
                                                        '&.Mui-checked': {
                                                            color: orange[600],
                                                        },
                                                    }}
                                                />}
                                            />

                                        </Box>

                                    </Box>
                                </RadioGroup>

                            </FormControl>

                            

                        </Stack>

                     }

                    </Grid>
                    */}

                    <Grid item xs={2}>

                        <Stack spacing={2}>


                            <LoadingButton
                                onClick={handleSubmit(onSubmit)}
                                endIcon={<ChevronRight />}
                                loading={isUpdatingDetails}
                                loadingPosition="end"
                                variant="contained"
                            >
                                <span>Confirm and continue</span>
                            </LoadingButton>

                            {showErrorAlert &&
                                <Alert variant="outlined" severity="error" onClose={handleAlertClose}>
                                    Something Went wrong
                                </Alert>
                            }

                        </Stack>
                    </Grid>


                </Grid>




            </Box>

            {/* 
            <Dialog
                fullWidth={true}
                maxWidth='md'
                open={dialogOpen}
                onClose={handleDialogClose}>
                <DialogTitle>Shipping Information<IconButton
                    aria-label="close"
                    onClick={handleDialogClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton></DialogTitle>
                <DialogContent>
                    <ShippingInformationDialog handleDialogClose={handleDialogClose} shippingInfo={shippingInfo} />
                </DialogContent>

            </Dialog>
            */}

        </React.Fragment>

    );
}

export default ShippingDetails;